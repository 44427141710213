import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import "../../style/login.css";

function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();

    async function signIn() {
        let item = { email, password };
        console.log(item);

        try {
            let response = await fetch('https://abavantage.pythonanywhere.com/api/user/login/', {
                method: 'POST',
                body: JSON.stringify(item),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            });

            let result = await response.json();
            console.log("result", result);

            if (response.ok && result.user && result.tokens) {
                if (result.user.is_startup) {
                    navigate('/about');
                } else {
                    navigate('/map');
                }
            } else {
                setErrorMessage(result.message || "Failed to login.");
            }
        } catch (error) {
            console.error('Error:', error);
            setErrorMessage("An error occurred during login.");
        }
    }

    useEffect(() => {
        if (errorMessage) {
            const timer = setTimeout(() => {
                setErrorMessage("");
            }, 3000); 

            return () => clearTimeout(timer);
        }
    }, [errorMessage]);

    return (
        <div className="login">
            <div className="form">
                <div className="form-title">
                    <h2>Login to Your Account</h2>
                    <Link to="../">
                        <i className="fa-solid fa-arrow-right-long"></i>
                    </Link>
                </div>

                {errorMessage && 
                    <span className="error">
                        <div className="alert alert-danger" id="err-mess">{errorMessage}</div>
                    </span>
                }

                <div className="flex-column">
                    <label>Email </label>
                </div>
                <div className="inputForm">
                    <i className="fa-solid fa-at"></i>
                    <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="input" placeholder="Enter your Email" />
                </div>

                <div className="flex-column">
                    <label>Password </label>
                </div>
                <div className="inputForm">
                    <i className="fa-solid fa-lock"></i>
                    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} className="input" placeholder="Enter your Password" />
                </div>

                <button className="button-submit mt-3 mb-3" onClick={signIn}>
                    Login
                    <svg fill="currentColor" viewBox="0 0 24 24" class="icon">
                        <path
                            clip-rule="evenodd"
                            d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z"
                            fill-rule="evenodd"
                        ></path>
                    </svg>
                </button>
            </div>
        </div>
    );
}

export default Login;
