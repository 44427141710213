import React, { Component } from "react";
import { useNavigate, Link } from 'react-router-dom';
import "../../style/style.css";
import Logo from '../../assets/img/logo.png';

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      isBlurred: false,
    };
  }

  handleToggleOffcanvas = () => {
    this.setState((prevState) => ({
      isVisible: !prevState.isVisible,
      isBlurred: !prevState.isVisible,
    }));
  };
  
  handleHideOffcanvas = () => {
    this.setState({ isVisible: false, isBlurred: false });
  };

  render() {
    const { isVisible, isBlurred } = this.state;

    const scrollToTop = () => {
      window.scrollTo(0, 0);
    }

    // const { t } = this.props; 

    return (
      // Header
      <header>
        <nav className="navbar navbar-expand-lg">
          <div className="container-fluid">

            <a className="navbar-brand" href="#Top" onClick={scrollToTop}>
              <div className="logo-item">
                  <div className="icon-box">
                      <img src={Logo} alt="Navbar Logo" />
                      {/* <h1>CheckToFund</h1> */}
                  </div>
              </div>
            </a>

            <button className={`navbar-toggler ${isVisible ? "active" : ""}`} type="button" onClick={this.handleToggleOffcanvas} aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
              <i className="fa-solid fa-bars-staggered"></i>
            </button>

            <div className={`offcanvas offcanvas-end ${isVisible ? 'show' : ''}`} tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">

              <div className="offcanvas-header">
                <button className="button1" onClick={this.handleHideOffcanvas} aria-label="Close">
                  <i id="close" className="fa-solid fa-xmark"></i>
                </button>
              </div>

              <div className="offcanvas-body">
                <ul className="navbar-nav justify-content-end flex-grow-1 pe-3" onClick={() => { this.handleHideOffcanvas(); this.setState({ isBlurred: false }); }}>  

                  <li className="nav-item">
                    <a className="nav-link" href="#Top" onClick={scrollToTop}>
                      <h5>Home</h5>
                    </a>
                  </li>

                  <li className="nav-item">
                    <a className="nav-link" target="blank" href="https://abavantage.com/">
                      <h5>About Us</h5>
                    </a>
                  </li>

                  {/* <li className="nav-item">
                    <a className="nav-link" href="#service">
                      <h5>Services</h5>
                    </a>
                  </li> */}

                  <li className="nav-item">
                    <Link className="nav-link" to="/login">
                      <h5>Login</h5>
                    </Link>
                  </li>

                  {/* <li className="nav-item">
                    <a className="nav-link" href="#team">
                      <h5 ><Link to="/reges">Register</Link></h5>
                    </a>
                  </li>

                  <li className="nav-item">
                    <a className="nav-link" href="#office">
                      <h5 ><Link to="/about">Chat Ai</Link></h5>
                    </a>
                  </li>

                  <li className="nav-item">
                    <a className="nav-link" href="#project">
                      <h5 ><Link to="/map">map</Link></h5>
                    </a>
                  </li> */}

                  {/* <li className="nav-item">
                    <a className="nav-link" href="#contact">
                      <h5> Contact</h5>
                    </a>
                  </li> */}

                </ul>

              </div>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

export default Navbar;
