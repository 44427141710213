import React from "react";
import { BrowserRouter as Router, Route, Routes, Link, Outlet } from 'react-router-dom';
import "./App.css";
import Navbar from "./components/navbar/navbar";
// import Team from "./components/team/team";
import Service from "./components/service/service";
import Project from "./components/project/project";
// import Contact from "./components/contact/contact";
import About from "./components/about/about";
import Home from "./components/home/home";
import ChatForm from "./components/home/chatForm";
import StartupForm from "./components/home/form";
import Login from "./components/login/login";
import Register from "./components/reges/reges";
import ConfirmOTP from "./components/reges/confirm-otp";
import Footer from "./components/footer/footer";
import Map from "./components/map/map";

function App() {
  return (
    <Router>
      {/* <Navbar /> */}
      <Routes>
        <Route path="/" element={<Homee />} />
        <Route path="/navbar" element={<Navbar />} />
        {/* <Route path="/service" element={<Service />} /> */}
        {/* <Route path="/project" element={<Project />} /> */}
        <Route path="/map" element={<Map />} />
        <Route path="/about" element={<About />} />
        {/* <Route path="/team" element={<Team />} /> */}
        {/* <Route path="/contact" element={<Contact />} /> */}
        <Route path="/login" element={<Login />} />
        <Route path="/reges" element={<Register />} />
        <Route path="/confirm-otp" element={<ConfirmOTP />} />
      </Routes>
    </Router>
  );
}

function Homee() {
  return (
    <>
      <Navbar/>
      {/* <About /> */}
      {/* <Home/> */}
      <ChatForm/>
      {/* <StartupForm /> */}
      {/* <Service /> */}
      {/* <Project /> */}
      {/* <MapComponent /> */}
      {/* <Team /> */}
      {/* <Contact /> */}
      {/* <Footer /> */}
    </>
  );
}

export default App;