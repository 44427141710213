import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';

function ConfirmOTP() {
    const location = useLocation();
    const navigate = useNavigate();
    const { user_id } = location.state || {};
    const [otp, setOtp] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleConfirmOtp = async () => {
        console.log('User ID:', user_id);
        console.log('OTP:', otp);
        
        try {
            let response = await fetch('https://abavantage.pythonanywhere.com/api/user/confirm-email/', {
                method: 'POST',
                body: JSON.stringify({ user_id, otp }),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            });

            if (!response.ok) {
                const text = await response.text();
                throw new Error(`Server error: ${text}`);
            }

            let result = await response.json();
            console.log('Response result:', result);

            if (result.success) {
                setSuccessMessage("OTP confirmed successfully!");
                setErrorMessage("");
                console.log('OTP confirmed successfully, navigating to login page...');
                navigate('/login');
            } else {
                setSuccessMessage("");
                setErrorMessage(result.message || "OTP confirmation failed.");
                console.error('OTP confirmation failed:', result.message);
                navigate('/login');
            }
        } catch (error) {
            console.error('Error:', error);
            setErrorMessage("An error occurred: " + error.message);
        }
    };

    useEffect(() => {
        if (errorMessage) {
            const timer = setTimeout(() => {
                setErrorMessage("");
            }, 3000); 
            
            return () => clearTimeout(timer);
        }
    }, [errorMessage]);

    return (
        <div className="confirm-otp">
            {user_id ? (
                <div className="login">
                    <div className="form">
                        <div className="form-title d-block align-center">
                            <h2>Check your Email <i class="fa-solid fa-envelope"></i></h2>
                        </div>
                        
                        {errorMessage && 
                            <span className="error">
                                <div className="alert alert-danger" id="err-mess">{errorMessage}</div>
                            </span>
                        }

                        {successMessage && <div className="alert alert-success">{successMessage}</div>}

                        <div className="flex-column">
                            <label>Enter OTP</label>
                        </div>
                        <div className="inputForm">
                            <i className="fa-solid fa-key"></i>
                            <input type="text" value={otp} onChange={(e) => setOtp(e.target.value)} className="input" placeholder="Enter Your OTP.." />
                        </div>

                        <button className="button-submit mt-3 mb-3" onClick={handleConfirmOtp}>
                            Confirm OTP
                            <svg fill="currentColor" viewBox="0 0 24 24" class="icon">
                                <path
                                    clip-rule="evenodd"
                                    d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z"
                                    fill-rule="evenodd"
                                ></path>
                            </svg>
                        </button>

                        <p className="p">
                            If Email has been confirmed before?
                            <Link to="../login">
                                <span className="span">Login</span>
                            </Link>
                        </p>
                    </div>
                </div>
            ) : (
                <div className="login">
                    <div className="form">

                        <div className="form-title">
                            <h1>Confirm OTP</h1>
                            <Link to="../">
                                <i className="fa-solid fa-arrow-right-long"></i>
                            </Link>
                        </div>

                        <div className="flex-column pt-5">
                            <span className="error">
                                <div className="alert alert-danger" id="err-mess">You must Sign Up first</div>
                            </span>

                            <Link to="../reges">
                                <button className="button-submit">
                                    Sign Up
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ConfirmOTP;
