import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import "../../style/login.css";

function Register() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [is_startup, setType] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();

    async function signUp() {
        let item = { name, email, password, is_startup };
        console.warn(item);

        try {
            let result = await fetch('https://abavantage.pythonanywhere.com/api/users/', {
                method: 'POST',
                body: JSON.stringify(item),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            });
            result = await result.json();
            console.warn("result", result);
            if (result.message) {
                setErrorMessage(result.message);
            } else {
                // التأكد من أن user.id موجود في النتيجة
                if (result.user && result.user.id) {
                    navigate('/confirm-otp', { state: { user_id: result.user.id } });
                } else {
                    setErrorMessage("User ID not found in the response.");
                }
            }
        } catch (error) {
            console.error('Error:', error);
            setErrorMessage("An error occurred during registration.");
        }
    }

    useEffect(() => {
        if (errorMessage) {
            const timer = setTimeout(() => {
                setErrorMessage("");
            }, 3000); 
            
            return () => clearTimeout(timer);
        }
    }, [errorMessage]);

    return (
        <div className="login">
            <div className="form">
                <div className="form-title">
                    <h2>Create an Account</h2>
                    <Link to="../">
                        <i className="fa-solid fa-arrow-right-long"></i>
                    </Link>
                </div>

                {errorMessage && <span className="error">
                    <div className="alert alert-danger" id="err-mess">{errorMessage}</div>
                </span>}

                <div className="flex-column">
                    <label>Name </label>
                </div>
                <div className="inputForm">
                    <i className="fa-regular fa-user"></i>
                    <input required type="text" value={name} onChange={(e) => setName(e.target.value)} className="input" placeholder="Enter your Name" />
                </div>

                <div className="flex-column">
                    <label>Email </label>
                </div>
                <div className="inputForm">
                    <i className="fa-solid fa-at"></i>
                    <input required type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="input" placeholder="Enter your Email" />
                </div>

                <div className="flex-column">
                    <label>Password </label>
                </div>
                <div className="inputForm">
                    <i className="fa-solid fa-lock"></i>
                    <input required type="password" value={password} onChange={(e) => setPassword(e.target.value)} className="input" placeholder="Enter your Password" />
                </div>

                <div className="radio-flex">
                    <div className="flex-check">
                        <div className="radio-wrapper">
                            <label className="radio-btn">
                                <input type="radio" value="true" checked={is_startup === 'true'} onChange={(e) => setType(e.target.value)} />
                                <span className="checkmark"></span>
                                <span>Startup</span>
                            </label>
                        </div>

                        <div className="radio-wrapper">
                            <label className="radio-btn">
                                <input type="radio" value="false" checked={is_startup === 'false'} onChange={(e) => setType(e.target.value)} />
                                <span className="checkmark"></span>
                                <span>Investor</span>
                            </label>
                        </div>
                    </div>
                </div>

                <button className="button-submit mt-3 mb-3" onClick={signUp}>
                    Sign In
                    <svg fill="currentColor" viewBox="0 0 24 24" class="icon">
                        <path
                            clip-rule="evenodd"
                            d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z"
                            fill-rule="evenodd"
                        ></path>
                    </svg>
                </button>

                {/* <p className="p line" id="or">OR</p>
                <button className="button-submit" id="google-btn">
                    <Link to="../about">
                        <i className="fa-brands fa-google"></i> Continue with Google
                    </Link>
                </button> */}

                <p className="p">
                    Already have an account?
                    <Link to="../login">
                        <span className="span">Login</span>
                    </Link>
                </p>
            </div>
        </div>
    );
}

export default Register;






// import React, { useState } from "react";
// import { Link } from 'react-router-dom';
// import axios from 'axios';
// import "../../style/login.css";

// function Register() {
//     const [name, setName] = useState("");
//     const [email, setEmail] = useState("");
//     const [password, setPassword] = useState("");
//     const [is_startup, setType] = useState("");
//     const [errorMessage, setErrorMessage] = useState("");

//     async function signUp() {

//         let item ={name, email, password, is_startup}
//         console.warn(item);

//         try {
//             let result = await fetch('https://abavantage.pythonanywhere.com/api/users/', {
//                 method: 'POST',
//                 body: JSON.stringify(item),
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Accept': 'application/json',
//                 }
//             });
//             result = await result.json();
//             console.warn("result", result);
//             if (result.message) {
//                 setErrorMessage(result.message);
//             } else {
//                 // handle success
//             }
//         } catch (error) {
//             console.error('Error:', error);
//         }
//     }

//     return (
//         <div className="login">
//             <div className="form">
//                 <div className="form-title">
//                     <h2>Create an Account</h2>
//                     <Link to="../">
//                         <i className="fa-solid fa-arrow-right-long"></i>
//                     </Link>
//                 </div>

                
//                     {errorMessage && <span className="error">
//                         <div class="alert alert-danger" id="err-mess">{errorMessage}</div>
//                     </span>}
                

//                 <div className="flex-column">
//                     <label>Name </label>
//                 </div>
//                 <div className="inputForm">
//                     <i className="fa-regular fa-user"></i>
//                     <input required type="text" value={name} onChange={(e) => setName(e.target.value)} className="input" placeholder="Enter your Name" />
//                 </div>

//                 <div className="flex-column">
//                     <label>Email </label>
//                 </div>
//                 <div className="inputForm">
//                     <i className="fa-solid fa-at"></i>
//                     <input required type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="input" placeholder="Enter your Email" />
//                 </div>

//                 <div className="flex-column">
//                     <label>Password </label>
//                 </div>
//                 <div className="inputForm">
//                     <i className="fa-solid fa-lock"></i>
//                     <input required type="password" value={password} onChange={(e) => setPassword(e.target.value)} className="input" placeholder="Enter your Password" />
//                 </div>

//                 <div className="radio-flex">
//                     <div className="flex-check">
//                         <div className="radio-wrapper">
//                             <label className="radio-btn">
//                                 <input type="radio" value="true" checked={is_startup === 'true'} onChange={(e) => setType(e.target.value)} />
//                                 <span className="checkmark"></span>
//                                 <span>Startup</span>
//                             </label>
//                         </div>

//                         <div className="radio-wrapper">
//                             <label className="radio-btn">
//                                 <input type="radio" value="false" checked={is_startup === 'false'} onChange={(e) => setType(e.target.value)} />
//                                 <span className="checkmark"></span>
//                                 <span>Investor</span>
//                             </label>
//                         </div>
//                     </div>
//                 </div>

//                 <button className="button-submit" onClick={signUp}>
//                     {/* <Link to="../about"> */}
//                         Sign In
//                     {/* </Link> */}
//                 </button>

//                 <p className="p line" id="or">OR</p>

//                 <button className="button-submit" id="google-btn">
//                     <Link to="../about">
//                     <i className="fa-brands fa-google"></i> Continue with Google
//                     </Link>
//                 </button>

//                 <p className="p">
//                     Already have an account?
//                     <Link to="../login">
//                         <span className="span">Login</span>
//                     </Link>
//                 </p>
//             </div>
//         </div>
//     );
// }

// export default Register;



// constructor(props) {
    //     super(props);
    //     this.state = {
    //         selectedOption: 'Startup',
    //     };
    // }
    
    // handleOptionChange = (event) => {
    //     this.setState({
    //         selectedOption: event.target.value,
    //     });
    // };

    //     const { selectedOption } = this.state;