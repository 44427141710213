import React, { Component, createRef } from "react";
import { Link } from 'react-router-dom';
import "../../style/about.css";
import Logo from '../../assets/img/logo.png';

class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputText: "",
            chatHistory: [],
            loading: false
        };
        this.chatEndRef = createRef(); // إنشاء مرجع للعنصر الأخير في الدردشة
    }

    handleInputChange = (event) => {
        this.setState({ inputText: event.target.value });
    };

    handleSendMessage = () => {
        // Update chat history with user message
        const userMessage = this.state.inputText;
        this.setState({ loading: true, chatHistory: [...this.state.chatHistory, { message: this.state.inputText, sender: "user" }], inputText: "" });
    
        fetch("https://abavantage.pythonanywhere.com/ChatBot/Generate_Text_with_Gemini", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: '',
            },
            body: JSON.stringify({ message: userMessage, Prompt: userMessage })
        })
        .then(response => response.json())
        .then(data => {
            // Add AI response to chat history and hide loader
            const aiMessage = data.data;
            this.setState((prevState) => ({
                chatHistory: [...prevState.chatHistory, { message: aiMessage, sender: "ai" }],
                loading: false, // Hide loader
            }), this.scrollToTopOfLastMessage); // Call scrollToTopOfLastMessage after updating the state
        })
        .catch(error => {
            console.error("Error fetching text:", error);
            // Hide loader in case of error
            this.setState({ loading: false });
        });
    };

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.handleSendMessage();
        }
    };

    scrollToTopOfLastMessage = () => {
        if (this.chatEndRef.current) {
            this.chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    renderMessageWithAnimation = (message) => {
        return message.split(' ').map((word, index) => (
            <span key={index} className="word" style={{ animationDelay: `${index * 0.15}s` }}>
                {word}
            </span>
        ));
    };

    render() {
        return (
            <>
            <div className="about" id="Home">
                <div className="chatbox">
                    <nav>
                        <img className="img" src={Logo} alt="Navbar Logo" />
                        <h6><i className="fa fa-comments"></i>&nbsp;&nbsp;Chat with Me</h6>
                    </nav>
                    <div className="chat-body">

                        {(!this.state.chatHistory.length && !this.state.loading) && (
                            <div className="title">
                                <h1>The future of startup funding</h1>
                            </div>
                        )}
                    
                        <div className="chat-history">
                            {this.state.chatHistory.map((message, index) => (
                                <div key={index}>
                                    {/* عنصر نهاية الدردشة لتمرير العرض إليه */}
                                    {message.sender === "ai" && <div ref={this.chatEndRef} />}
                                    <div className={message.sender === "user" ? "sender" : "receiver"}>
                                        <p>{message.sender === "user" ? message.message : this.renderMessageWithAnimation(message.message)}</p>
                                        {/* <i className={message.sender === "user" ? "fa-regular fa-circle-user" : "fa-solid fa-robot"}></i> */}

                                        {message.sender === "user" ? (
                                            <i className="fa-regular fa-circle-user"></i>
                                        ) : (
                                            <p>
                                            <svg height="24" width="24" fill="#FFFFFF" viewBox="0 0 24 24" data-name="Layer 1" id="Layer_1" class="sparkle">
        <path d="M10,21.236,6.755,14.745.264,11.5,6.755,8.255,10,1.764l3.245,6.491L19.736,11.5l-6.491,3.245ZM18,21l1.5,3L21,21l3-1.5L21,18l-1.5-3L18,18l-3,1.5ZM19.333,4.667,20.5,7l1.167-2.333L24,3.5,21.667,2.333,20.5,0,19.333,2.333,17,3.5Z"></path>
    </svg>
    </p>
                                        )}
                                    </div>
                                </div>
                            ))}

                            {this.state.loading && 
                                <div className="loader"></div>
                            }
                        </div>
                    </div>
                    <div className="typer">
                        <input
                            type="text"
                            placeholder="Send a message."
                            value={this.state.inputText}
                            onChange={this.handleInputChange}
                            onKeyPress={this.handleKeyPress}
                        />
                        
                        <button onClick={this.handleSendMessage}>
                            <i id="send" className="fa-solid fa-paper-plane"></i>
                        </button>
                    </div>
                </div>
            </div>
            </>
        );
    }
}

export default About;
