import React, { Component } from 'react'; 
import { Link } from 'react-router-dom';
import "../../style/style.css";

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messages: [],
            currentMessage: '',
            stages: [
                { question: 'Hi :) Welcome to ChecktoFund; the future of startup funding! I am C2F ChecktoFund AI chatbot and I’m here to help you :) Are you an investor or a startup?', key: 'type' },
                { question: 'What is your startup’s name?', key: 'name', dependsOn: 'userType', dependsValue: 'startup' },
                { question: 'Great name! So, where are you based?', key: 'based', dependsOn: 'userType', dependsValue: 'startup' },
                { question: 'Cool place! Who is your target customer?', key: 'customer', dependsOn: 'userType', dependsValue: 'startup' },
                { question: 'Interesting! When was XYZ started?', key: 'started', dependsOn: 'userType', dependsValue: 'startup' },
                { question: 'Who are the founders, and what\'s their background?', key: 'founders', dependsOn: 'userType', dependsValue: 'startup' },
                { question: 'How many employees work in XYZ?', key: 'employees', dependsOn: 'userType', dependsValue: 'startup' },
                { question: 'What\'s XYZ’s differentiator? Tell me what makes you special!', key: 'differentiator', dependsOn: 'userType', dependsValue: 'startup' },
                { question: 'I sense enthusiasm! Do you have an MVP(Yes/No)?', key: 'mvp', dependsOn: 'userType', dependsValue: 'startup' },
                { question: 'Do you have customers or partnerships?', key: 'customers', dependsOn: 'userType', dependsValue: 'startup' },
                { question: 'Do you have revenue?', key: 'revenue', dependsOn: 'userType', dependsValue: 'startup' },
                { question: 'Okay so what\'s XYZ’s revenue model?', key: 'revenueModel', dependsOn: 'userType', dependsValue: 'startup' },
                { question: 'Creative! Have you raised funding? If yes, how much?', key: 'funding', dependsOn: 'userType', dependsValue: 'startup' },
                { question: 'How can we contact you?', key: 'contact', dependsOn: 'userType', dependsValue: 'startup' },
                { question: 'We’ll definitely be in touch! How do you plan to use new funding?', key: 'useFunding', dependsOn: 'userType', dependsValue: 'startup' },
                { question: 'Send us a google drive link containing XYZ’s pitch deck & other important files. Don’t forget to give "ab@abavantage.com" permission view it :)', key: 'pitchDeck', dependsOn: 'userType', dependsValue: 'startup' },
                { question: 'Click here to finalize your registration! (Enter email and password)', key: 'finalize', dependsOn: 'userType', dependsValue: 'startup' }
            ],
            currentStage: 0,
            userType: ''
        };
    }

    handleChange = (event) => {
        this.setState({ currentMessage: event.target.value });
    };

    handleSubmit = (event) => {
        event.preventDefault();
        const { stages, currentStage, currentMessage, messages } = this.state;
    
        // Add user message to chat
        this.setState({
            messages: [...messages, { text: currentMessage, sender: 'user' }],
            currentMessage: ''
        });
        
    
        // Check if there's a next stage and send bot message
        if (currentStage < stages.length) {
            const stage = stages[currentStage];
            let nextStage = currentStage + 1;
    
            // Validate user response
            if (stage.key === 'type' && currentMessage.toLowerCase() !== 'startup' && currentMessage.toLowerCase() !== 'investor') {
                nextStage = currentStage; // Stay on the same stage
                this.setState({
                    messages: [...this.state.messages, { text: 'Please answer with either "startup" or "investor".', sender: 'bot' }]
                });
            } else if (stage.dependsOn && (!this.state[stage.dependsOn] || (stage.dependsValue && this.state[stage.dependsOn] !== stage.dependsValue))) {
                nextStage = currentStage;
            }
    
            if (nextStage !== currentStage) {
                setTimeout(() => {
                    this.setState({ currentStage: nextStage }, () => {
                        let botMessage = '';
                        if (stage.key === 'type') {
                            if (currentMessage.toLowerCase() === 'investor') {
                                botMessage = 'This is good to hear! at ChecktoFund we do AI customized services for each investor based on his business appetite! We will get back to you with a tailored suite of high potential startups and other services! How can we contact you?';
                            } else if (currentMessage.toLowerCase() === 'startup') {
                                botMessage = 'What is your startup\'s name?';
                            }
                            this.setState({
                                userType: currentMessage.toLowerCase()
                            });
                        } else {
                            if (stage.key === 'name') {
                                this.setState({
                                    startupName: currentMessage
                                });
                                botMessage = `Great name! So, where are you based, ${currentMessage}?`;
                            } else if (stage.key === 'mvp') {
                                if (currentMessage.toLowerCase() === 'yes') {
                                    botMessage = 'Do you have customers or partnerships?';
                                    nextStage += 1; // Skip next question if MVP is yes
                                } else {
                                    botMessage = 'Okay so what\'s XYZ’s revenue model?';
                                }
                            } else {
                                const nextQuestion = stages[nextStage].question.replace('XYZ', this.state.startupName || '');
                                botMessage = nextQuestion;
                            }                            
                        }
                        this.setState({
                            messages: [...this.state.messages, { text: botMessage.replace('XYZ', this.state.startupName || ''), sender: 'bot' }]
                        });                        
                    });
                }, 1000); // Delay in milliseconds
            }
        }
    };
    
    
    render() {
        const { userType, stages, messages, currentStage } = this.state;
    
        let chatMessages = [
            <div key="0" className="message bot">Hi :) Welcome to ChecktoFund; the future of startup funding! I am C2F ChecktoFund AI chatbot and I’m here to help you :) Are you an investor or a startup?</div>
        ];
    
        messages.forEach((message, index) => {
            chatMessages.push(
                <div key={index + 1} className={`message ${message.sender}`}>
                    {message.text}
                </div>
            );
        });
    
        let inputForm = (
            <div>
                <input type="text" value={this.state.currentMessage} onChange={this.handleChange} />
                <button type="submit">Send</button>
            </div>
        );
    
        // تحقق إذا كان المرحلة الحالية تطلب إرسال رابط Google Drive
        if (currentStage < stages.length && stages[currentStage].key === 'finalize') {
            chatMessages.push(
                <div key={messages.length} className={`message bot`}>
                    {stages[currentStage].question.replace('XYZ', this.state.startupName || '')}
                    <Link to="/login" className="btn">Login</Link>
                </div>
                
            );
            inputForm = null; // إخفاء حقل إدخال الرسائل
        } else if (currentStage >= stages.length) {
            // عرض الزر للانتقال لصفحة أخرى
            return (
                <div className="chat-container">
                    <div className="chat">
                        {chatMessages}
                    </div>
                    <Link to="/login" className="btn">Login</Link>
                </div>
            );
        }
    
        return (
            <div className="chat-container">
                <div className="chatbox">
                    <div className="chat-body">
                        <div className="chat">
                            {chatMessages}
                        </div>
                    </div>
                    <form onSubmit={this.handleSubmit}>
                        {inputForm}
                    </form>
                </div>
            </div>
        );
    }
    
    
}

export default Home;




// let questions = [

//     {
//       text: 'Hi :) Welcome to ChecktoFund; the future of startup funding! I am C2F ChecktoFund AI chatbot and I’m here to help you :) Are you an investor or a startup?',
//       name: 'user_type'
//     },
//     {
//       text: 'What is your startup’s name?',
//       name: 'startup_name'
//     },
//     {
//       text: 'Great name! So, where are you based?',
//       name: 'location'
//     },
//     {
//       text: 'Cool place! Who is your target customer?',
//       name: 'target_customer'
//     },
//     {
//       text: 'Interesting! When was XYZ started?',
//       name: 'started_year'
//     },
//     {
//       text: 'Who are the founders, and what\'s their background?',
//       name: 'founders_background'
//     },
//     {
//       text: 'How many employees work in XYZ?',
//       name: 'num_Of_employees'
//     },
//     {
//       text: 'What\'s XYZ’s differentiator? Tell me what makes you special!',
//       name: 'differentiator'
//     },
//     {
//       text: 'I sense enthusiasm! Do you have an MVP? (yes/no)',
//       name: 'MVP'
//     },
//     {
//       text: 'Do you have customers or partnerships? (yes/no)',
//       name: 'customers_or_partnerships'
//     },
//     {
//       text: 'Do you have revenue? (yes/no)',
//       name: 'revenue'
//     },
//     {
//       text: 'Okay so what\'s XYZ’s revenue model?',
//       name: 'revenue_model'
//     },
//     {
//       text: 'Creative! Have you raised funding? If yes, how much?',
//       name: 'raised_funding'
//     },
//     {
//       text: 'How can we contact you?',
//       name: 'contact_info'
//     },
//     {
//       text: 'We’ll definitely be in touch! How do you plan to use new funding?',
//       name: 'funding_plan'
//     },
//     {
//       text: 'Send us a google drive link containing XYZ’s pitch deck & other important files. Don’t forget to give "ab@abavantage.com" permission view it :)',
//       name: 'drive_links'
//     }
//   ];

//   if (!isStartup) {
//     questions = [
//         {
//           text: 'Hi :) Welcome to ChecktoFund; the future of startup funding! I am C2F ChecktoFund AI chatbot and I’m here to help you :) Are you an investor or a startup?',
//           name: 'user_type'
//         },
//         {
//           text: 'This is good to hear! at ChecktoFund we do AI customized services for each investor based on his business appetite! We will get back to you with a tailored suite of high potential startups and other services! How can we contact you?',
//           name: 'connect_info'
//         }
//     ];
//   }






// const inputNames = [
//     "Startup_name",
//     "location",
//     "target_customer",
//     "started_year",
//     "founders_background",
//     "num_Of_employees",
//     "differentiator",
//     "MVP",
//     "customers_or_partnerships",
//     "revenue",
//     "revenue_model",
//     "raised_funding",
//     "contact_info",
//     "funding_plan",
//     "drive_links",
//   ];



//   if (!isStartup) {
//     questions = [
//       {
//         text: 'Hi :) Welcome to ChecktoFund; the future of startup funding! I am C2F ChecktoFund AI chatbot and I’m here to help you :) Are you an investor or a startup?',
//         value: 'user_type'
//       },
//       {
//         text: 'This is good to hear! at ChecktoFund we do AI customized services for each investor based on his business appetite! We will get back to you with a tailored suite of high potential startups and other services! How can we contact you?',
//         value: 'connect_info'
//       }
//     ];
//   }




// <div className="chat-container">
//                 <div className="chatbox">
//                     <div className="chat-body">
//                         <div className="chat">
//                             {chatMessages}
//                         </div>
//                     </div>
//                     <form onSubmit={this.handleSubmit}>
//                         {inputForm}
//                     </form>
//                 </div>
//             </div>