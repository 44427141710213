import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import "../../style/style.css";
import Typewriter from 'react-typewriter-effect';

const ChatForm = () => {
  const [isStartup, setIsStartup] = useState(null);
  const [answers, setAnswers] = useState(['', '', '', '', '', '', '', '', '', '', '', '', '', '']);
  const [step, setStep] = useState(0);
  const [error, setError] = useState(false);
  const [XYZ, setXYZ] = useState('');
  const [skipNextQuestions, setSkipNextQuestions] = useState(false);
  const [showChat, setShowChat] = useState(true);
  const newQuestionRef = useRef(null); 
  // Startup Answers 
  const [Startup_name, setName1] = useState("");
  const [location, setName2] = useState("");
  const [target_customer, setName3] = useState("");
  const [started_year, setName4] = useState("");
  const [founders_background, setName5] = useState("");
  const [num_Of_employees, setName6] = useState("");
  const [differentiator, setName7] = useState("");
  const [MVP, setName8] = useState("");
  const [customers_or_partnerships, setName9] = useState("");
  const [revenue, setName10] = useState("");
  const [revenue_model, setName11] = useState("");
  const [raised_funding, setName12] = useState("");
  const [contact_info, setName13] = useState("");
  const [funding_plan, setName14] = useState("");
  const [drive_links, setName15] = useState("");
  // Investor Answers 
  const [connect_info, setName16] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  let questions = [
    'Hi :) Welcome to ChecktoFund; the future of startup funding! I am C2F ChecktoFund AI chatbot and I’m here to help you :) Are you an investor or a startup?',
    'What is your startup’s name?',
    'Great name! So, where are you based?',
    'Cool place! Who is your target customer?',
    'Interesting! When was XYZ started?',
    'Who are the founders, and what\'s their background?',
    'How many employees work in XYZ?',
    'What\'s XYZ’s differentiator? Tell me what makes you special!',
    'I sense enthusiasm! Do you have an MVP? (yes/no)',
    'Do you have customers or partnerships? (yes/no)',
    'Do you have revenue? (yes/no)',
    'Okay so what\'s XYZ’s revenue model?',
    'Creative! Have you raised funding? If yes, how much?',
    'How can we contact you?',
    'We’ll definitely be in touch! How do you plan to use new funding?',
    'Send us a google drive link containing XYZ’s pitch deck & other important files. Don’t forget to give "ab@abavantage.com" permission view it :)'
  ];

  if (!isStartup) {
    questions = [
      'Hi :) Welcome to ChecktoFund; the future of startup funding! I am C2F ChecktoFund AI chatbot and I’m here to help you :) Are you an investor or a startup?',
      'This is good to hear! at ChecktoFund we do AI customized services for each investor based on his business appetite! We will get back to you with a tailored suite of high potential startups and other services! How can we contact you?',
    ];
  }

  if (XYZ !== '') {
    questions = questions.map(question => question.replace('XYZ', XYZ));
  }

  useEffect(() => {
    // دالة للتمرير إلى السؤال الجديد عندما يتغير step
    if (newQuestionRef.current) {
      newQuestionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [step]);

  const handleResponse = (e) => {
    e.preventDefault();
    const response = e.target.elements.response.value.trim();
    if (response) {
      if (step === 0 && response.toLowerCase() !== 'startup' && response.toLowerCase() !== 'investor') {
        setError(true);
        return;
      }
      setError(false);
      const newAnswers = [...answers];
      newAnswers[step] = response;
      setAnswers(newAnswers);
      if (step === 1) {
        setXYZ(response);
        setName1(response); 
        setName2(response); 
        setName3(response); 
        setName4(response); 
        setName5(response); 
        setName6(response); 
        setName7(response); 
        setName8(response); 
        setName9(response); 
        setName10(response);
        setName11(response);
        setName12(response);
        setName13(response);
        setName14(response);
        setName15(response);
        setName16(response);
      }
      setStep(step + 1);
      if (step === 0) {
        setIsStartup(response.toLowerCase() === 'startup');
      }
      if (step === 8 && response.toLowerCase() === 'no') {
        setSkipNextQuestions(true);
        setAnswers([...answers.slice(0, step + 1), null, null]);
        setStep(step + 3);
      } else {
        e.target.elements.response.value = '';
        setSkipNextQuestions(false);
      }
      setShowChat(step < questions.length - 1);
    }
  };

  const handleSubmit = async () => {
    if (step === questions.length) {
      const endpoint = isStartup ? 'https://abavantage.pythonanywhere.com/Startups/Add_new_startup/' : 'https://abavantage.pythonanywhere.com/Investors/Add_new_investor/';
      
      try {
        const data = isStartup ? {
          Startup_name: Startup_name,
          location: location,
          target_customer: target_customer,
          started_year: started_year,
          founders_background: founders_background,
          num_Of_employees: num_Of_employees,
          differentiator: differentiator,
          MVP: MVP,
          customers_or_partnerships: customers_or_partnerships,
          revenue: revenue,
          revenue_model: revenue_model,
          raised_funding: raised_funding,
          contact_info: contact_info,
          funding_plan: funding_plan,
          drive_links: drive_links,
          answers: answers.slice(1)

        } : {
          connect_info: connect_info,
          answers: answers.slice(1)
        };

        const response = await axios.post(endpoint, data);
        
        console.log('Data saved successfully:', response.data);
      } catch (error) {
        console.error('Error saving data:', error);
      }
    }
  };

  const renderChat = () => {
    const filteredAnswers = answers.filter(answer => answer !== '');
    const messages = filteredAnswers.map((answer, index) => (
      <div key={index} className={index % 2 === 0 ? 'bot-message' : 'user-message'}>
        <div className="message" id='bot-message'>
          <p>{questions[index]}</p>
        </div>
        <div className='message' id='user-message'>{answer}</div>
      </div>
    ));
  
    const botTypingMessage = !skipNextQuestions && showChat && (
      <div className="message" key={step} id="bot-message">
        <p id="mess-bot">
          {questions[step].split(' ').map((word, index) => (
            <span key={index} className="word" style={{ animationDelay: index * 0.15 + 's' }}>
              {word}
            </span>
          ))}
        </p>
      </div>
    );
  
    return (
      <div>
        {messages}
        {botTypingMessage}
      </div>
    );
  };

  const renderButton = () => {
    if (step === questions.length) {
      return (
        <div>
          <p>Click here to finalize your registration! (Enter email and password)</p>
          <Link to="/reges">
            <button className='btn' id='log-btn' onClick={handleSubmit}>
              Sign up
            </button>
          </Link>
          {/* <div className="saved-data">
            {answers.slice(1).map((answer, index) => (
              <div key={index + 1}>
                <p>{answer}</p>
              </div>
            ))}
          </div> */}
        </div>
      );
    }
  };

  return (
    <div className="chat-container">
      <div className="chatbox">
        <div className="chat-body">
          {renderChat()}
          {error && <p>Please answer with either "startup" or "investor</p>}
          {showChat && (
            <form onSubmit={handleResponse}>
              <input
                type="text"
                name="response"
                placeholder="type here.."
                autoFocus
              />
              <button type="submit" className='send-btn'>
                  <div class="svg-wrapper-1">
                    <div class="svg-wrapper">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                      </svg>
                    </div>
                  </div>
                  <span>Send</span>
              </button>
            </form>
          )}
          {renderButton()}
        </div>
        {/* السؤال الجديد */}
        <div ref={newQuestionRef}></div>
      </div>
    </div>
  );
};

export default ChatForm;
