import React from 'react';
import '../../style/map.css';
import FusionCharts from 'fusioncharts';
import Maps from 'fusioncharts/fusioncharts.maps';
// import Africa from 'fusioncharts/maps/fusioncharts.africa';
import Africa from 'fusionmaps/maps/fusioncharts.africa';

// تضمين الاستايلات الافتراضية للخريطة
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';

// إضافة التصديرات إلى FusionCharts
import ReactFC from 'react-fusioncharts';

// إضافة الخريطة إلى FusionCharts
ReactFC.fcRoot(FusionCharts, Maps, Africa, FusionTheme);



class MapComponent extends React.Component {
    render() {

        const countries_ids = [
            ["001", "Algeria"],
            ["002", "Angola"],
            ["003", "Benin"],
            ["004", "Botswana"],
            ["005", "Burkina Faso"],
            ["006", "Burundi"],
            ["007", "Cameroon"],
            ["059", "Canary Islands"],
            ["008", "Cape Verde"],
            ["009", "Central African Republic"],
            ["010", "Chad"],
            ["057", "Congo"],
            ["012", "Cote d'Ivoire"],
            ["013", "Democratic Republic of the Congo"],
            ["014", "Djibouti"],
            ["015", "Egypt"],
            ["016", "Equatorial Guinea"],
            ["017", "Eritrea"],
            ["018", "Ethiopia"],
            ["019", "Gabon"],
            ["056", "Gambia"],
            ["020", "Ghana"],
            ["021", "Guinea"],
            ["022", "Guinea-Bissau"],
            ["023", "Kenya"],
            ["024", "Lesotho"],
            ["025", "Liberia"],
            ["026", "Libya"],
            ["027", "Madagascar"],
            ["028", "Malawi"],
            ["029", "Mali"],
            ["030", "Mauritania"],
            ["058", "Mauritius"],
            ["032", "Morocco"],
            ["033", "Mozambique"],
            ["034", "Namibia"],
            ["035", "Niger"],
            ["036", "Nigeria"],
            ["038", "Rwanda"],
            ["040", "Sao Tome and Principe"],
            ["041", "Senegal"],
            ["042", "Seychelles"],
            ["043", "Sierra Leone"],
            ["044", "Somalia"],
            ["045", "South Africa"],
            ["060", "South Sudan"],
            ["046", "Sudan"],
            ["047", "Swaziland"],
            ["048", "Tanzania"],
            ["049", "Togo"],
            ["051", "Tunisia"],
            ["052", "Uganda"],
            ["011", "Union of Comoros"],
            ["053", "Western Sahara"],
            ["054", "Zambia"],
            ["055", "Zimbabwe"]
        ];


        const dataSource = {
          chart: {
            bgColor: "#002146", // لون الخلفية
            borderColor: "#002146", // لون الحدود
            borderThickness: "0",
            // stroke: "#002146"
          },
          colorrange: {
            gradient: "0",
            color: [
              {
                maxvalue: "50",
                displayvalue: "0-50 (Startups)",
                code: "#ff9e9c"
              },
              {
                maxvalue: "99",
                displayvalue: "51-99 (Startups)",
                code: "#ff6663"
              },
              {
                maxvalue: "299",
                displayvalue: "100-299 (Startups)",
                code: "#E41B17"
              },
              {
                maxvalue: "1000",
                displayvalue: "300+ (Startups)",
                code: "#800000"
              }
            ]
          },
          // colorrange: {
          //   gradient: "0",
          //   color: [
          //     {
          //       minvalue: "0",
          //       maxvalue: "49",
          //       code: "#FFFF00", // أصفر
          //       displayvalue: "Less than 50"
          //     },
          //     {
          //       minvalue: "50",
          //       maxvalue: "99",
          //       code: "#00FF00", // أخضر
          //       displayvalue: "50-99"
          //     },
          //     {
          //       minvalue: "100",
          //       maxvalue: "299",
          //       code: "#FF4500", // أحمر متوسط
          //       displayvalue: "100-299"
          //     },
          //     {
          //       minvalue: "300",
          //       maxvalue: "1000",
          //       code: "#8B0000", // أحمر غامق
          //       displayvalue: "300+"
          //     }
          //   ]
          // },
          data: [
            { id: "001", value: "150", tooltext: "Algeria, 150 Startup(s)" },
            { id: "002", value: "50", tooltext: "Angola, 50 Startup(s)" },
            { id: "003", value: "30", tooltext: "Benin, 30 Startup(s)" },
            { id: "004", value: "40", tooltext: "Botswana, 40 Startup(s)" },
            { id: "005", value: "25", tooltext: "Burkina Faso, 25 Startup(s)" },
            { id: "006", value: "15", tooltext: "Burundi, 15 Startup(s)" },
            { id: "007", value: "60", tooltext: "Cameroon, 60 Startup(s)" },
            { id: "008", value: "10", tooltext: "Cabo Verde, 10 Startup(s)" },
            { id: "009", value: "5", tooltext: "Central African Republic, 5 Startup(s)" },
            { id: "010", value: "8", tooltext: "Chad, 8 Startup(s)" },
            { id: "011", value: "3", tooltext: "Union of Comoros, 3 Startup(s)" },
            { id: "012", value: "13", tooltext: "Cote d'Ivoire, 13 Startup(s)" },
            { id: "013", value: "20", tooltext: "Democratic Republic of the Congo, 20 Startup(s)" },
            { id: "014", value: "2", tooltext: "Djibouti, 2 Startup(s)" },
            { id: "015", value: "647", tooltext: "Egypt, 647 Startup(s)" },
            { id: "016", value: "4", tooltext: "Equatorial Guinea, 4 Startup(s)" },
            { id: "017", value: "1", tooltext: "Eritrea, 1 Startup(s)" },
            { id: "018", value: "80", tooltext: "Ethiopia, 80 Startup(s)" },
            { id: "019", value: "7", tooltext: "Gabon, 7 Startup(s)" },
            { id: "020", value: "520", tooltext: "Ghana, 520 Startup(s)" },
            { id: "021", value: "10", tooltext: "Guinea, 10 Startup(s)" },
            { id: "022", value: "5", tooltext: "Guinea-Bissau, 5 Startup(s)" },
            { id: "023", value: "400", tooltext: "Kenya, 400 Startup(s)" },
            { id: "024", value: "8", tooltext: "Lesotho, 8 Startup(s)" },
            { id: "025", value: "5", tooltext: "Liberia, 5 Startup(s)" },
            { id: "026", value: "15", tooltext: "Libya, 15 Startup(s)" },
            { id: "027", value: "20", tooltext: "Madagascar, 20 Startup(s)" },
            { id: "028", value: "18", tooltext: "Malawi, 18 Startup(s)" },
            { id: "029", value: "22", tooltext: "Mali, 22 Startup(s)" },
            { id: "030", value: "6", tooltext: "Mauritania, 6 Startup(s)" },

            { id: "032", value: "429", tooltext: "Morocco, 429 Startup(s)" },
            { id: "033", value: "15", tooltext: "Mozambique, 15 Startup(s)" },
            { id: "034", value: "10", tooltext: "Namibia, 10 Startup(s)" },
            { id: "035", value: "8", tooltext: "Niger, 8 Startup(s)" },
            { id: "036", value: "500", tooltext: "Nigeria, 500 Startup(s)" },

            { id: "038", value: "35", tooltext: "Rwanda, 35 Startup(s)" },
            
            { id: "040", value: "2", tooltext: "Sao Tome and Principe, 2 Startup(s)" },
            { id: "041", value: "20", tooltext: "Senegal, 20 Startup(s)" },
            { id: "042", value: "5", tooltext: "Seychelles, 5 Startup(s)" },
            { id: "043", value: "15", tooltext: "Sierra Leone, 15 Startup(s)" },
            { id: "044", value: "2", tooltext: "Somalia, 2 Startup(s)" },
            { id: "045", value: "340", tooltext: "South Africa, 340 Startup(s)" },
            { id: "046", value: "10", tooltext: "Sudan, 10 Startup(s)" },
            { id: "047", value: "5", tooltext: "Swaziland, 5 Startup(s)" },
            { id: "048", value: "90", tooltext: "Tanzania, 90 Startup(s)" },
            { id: "049", value: "8", tooltext: "Togo, 8 Startup(s)" },

            { id: "051", value: "415", tooltext: "Tunisia, 415 Startup(s)" },
            { id: "052", value: "100", tooltext: "Uganda, 100 Startup(s)" },
            { id: "053", value: "27", tooltext: "Western Sahara, 27 Startup(s)" },
            { id: "054", value: "60", tooltext: "Zambia, 60 Startup(s)" },
            { id: "055", value: "25", tooltext: "Zimbabwe, 25 Startup(s)" },
            { id: "056", value: "12", tooltext: "Gambia, 12 Startup(s)" },
            { id: "057", value: "23", tooltext: "Congo, 32 Startup(s)" },
            { id: "058", value: "9", tooltext: "Mauritius, 9 Startup(s)" },
            { id: "059", value: "33", tooltext: "Canary Islands, 33 Startup(s)" },
            { id: "060", value: "1", tooltext: "South Sudan, 1 Startup(s)" },



          ]
        };

        // { id: "024000000000", value: "40", tooltext: "Ivory Coast, 40 Startup(s)" },
        
      // تحديد تكوينات الخريطة
      const chartConfigs = {
        type: 'africa',
        width: '100%',
        height: '500',
        dataFormat: 'json',
        dataSource: {
          // قم بتحديد بيانات الخريطة هنا
          // "chart": {
          // },
          // "data": [
          //   // يمكنك تحديد بيانات المناطق هنا
          //   {
          //     "id": "NA",
          //     "value": "220"
          //   },
          //   {
          //     "id": "AS",
          //     "value": "400"
          //   },
          //   // قم بإضافة بيانات المناطق الأخرى هنا
          // ]
        }
      };
  
      return (
        <div className="map-container">
          {/* <ReactFC {...chartConfigs} /> */}
          <ReactFC
          {...chartConfigs}
            dataSource={dataSource}
          />
        </div>
      );
    }
  }
  
  export default MapComponent;
  